<template>
  <ik-data-table
    :entity-name="$t('named_table_round_list')"
    :icon="'mdi-devices'"
    :model="roundNameModel"
  />
</template>

<script>
  import NamedTableRoundList from '../../../model/restaurant/NamedTableRoundList'
  import { IkDataTable } from 'metaflow-js'

  export default {
    name: 'NamedTableRoundList',
    components: { IkDataTable },
    data () {
      return {
        roundNameModel: NamedTableRoundList,
      }
    },
  }
</script>

<style scoped>

</style>
