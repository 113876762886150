import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import GlobalSettings from '@/GlobalSettings'
import StringEntity from '@/model/common/StringEntity'

const entity = {

  id: {
    type: IKDataEntity.Types.Integer,
    form: false,
  },

  tableRound: {
    type: IKDataEntity.Types.Integer,
    displayName: 'table_round_id',
  },

  color: {
    type: IKDataEntity.Types.Color,
  },

  isActive: {
    type: IKDataEntity.Types.Boolean,
  },

  _langsname: {
    displayName: 'round_name',
    form: false,
  },

  langs: {
    displayName: 'name',
    type: IKDataEntity.Types.Group,
    formConfig: {
      groupName: 'name',
      default: GlobalSettings.supportLang,
      childLabelKey: 'lang',
      type: {},
    },
    tableConfig: {
      displayCondition: (item) => {
        return item.lang.toLowerCase() === GlobalSettings.getLang().toLowerCase()
      },
      displayChild: (key) => {
        return key === 'name'
      },
    },
    childKey: ['name'],
    labelKey: 'lang',
    children: [
      StringEntity, StringEntity, StringEntity,
    ],
    header: false,
  },

}

const config = {

  load: async function (filter) {
    const res = (await hillo.get('Route.php', {
      op: 'namedTableRoundList',
      // lang: GlobalSettings.getLang(),
      ...filter,
    })).content

    // console.log('load res', res)
    return res
  },

  add: function (item) {
    item.isActive = item.isActive ? 1 : 0
    return hillo.postWithUploadFile('Route.php?op=namedTableRoundAdd', {
      ...item,
      langs: JSON.stringify(item.langs),
    })
  },

  remove: function (id) {
    return hillo.post('Route.php?op=namedTableRoundDelete', { id: id })
  },

  edit: function (item) {
    item.isActive = item.isActive ? 1 : 0

    return hillo.postWithUploadFile('Route.php?op=namedTableRoundUpdate', {
      ...item,
      langs: JSON.stringify(item.langs),
    })
  },

}

export default IKDataEntity.ModelFactory(entity, config)
